import { createReducer, on } from '@ngrx/store';
import * as fromActions from './dashboard.actions';
import { MMTileInfo } from './dashboard.model';

export interface State {
  mmTileInfo: MMTileInfo | null;
  isLoading: boolean;
}

export const initialState: State = {
  mmTileInfo: null,
  isLoading: false,
};

export const dashboardReducer = createReducer(
  initialState,
  on(fromActions.getMMTileInfo, state => ({ ...state, isLoading: true })),
  on(fromActions.getMMTileInfoComplete, (state, { mmTileInfo }) => ({ ...state, isLoading: false, mmTileInfo })),
  on(fromActions.getMMTileInfoError, state => ({ ...state, isLoading: false })),
);
