import * as moment from 'moment';

/**
 * Example output: "2019-10-24T00:00:000Z"
 */
export function formatToISODate(date?: string): string | null {
  /*
   * date = '2019/10/24'
   * moment.utc(moment(date)).toISOString()                        -> '2019-10-23T21:00:00.000Z' (day is wrong)
   * moment.utc(moment(date)).format('YYYY-MM-DD')).toISOString()  -> '2019-10-24T00:00:00.000Z'
   */
  return date ? moment.utc(moment(date).format('YYYY-MM-DD')).toISOString() : null;
}

export function getDateWithZeroTime(date: string | Date): Date {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
}

export function getLocalTimeFromISOString(isoString: string): string {
  const dateTimeObj = new Date(isoString);

  return dateTimeObj.toLocaleTimeString(Intl.DateTimeFormat().resolvedOptions().locale, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });
}
