import { HighlanderService } from '@1clickfactory/data-access/highlander.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MMTileInfo } from './dashboard.model';

@Injectable()
export class DashboardService {
  constructor(private highlanderService: HighlanderService) {}

  getMMTileInfo(): Observable<MMTileInfo> {
    return this.highlanderService.get<MMTileInfo>(`dashboard/mm-report`);
  }
}
