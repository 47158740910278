<cui-dashboard-base>
  @if ((hasMMTileAccess$ | async)!) {
    <ocf-mm-tile
      [ngStyle]="{
        'grid-row': 'span 1',
        'grid-column': 'span 2'
      }"
    ></ocf-mm-tile>
  }
</cui-dashboard-base>
