import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ActionQueuedResponse, Environment, EnvironmentListItem, EnvironmentsRefreshRequest } from './environment.model';
import { FilterToApply, State } from './environments.reducer';

const NAMESPACE = '[Environments]';

export const getAllEnvironments = createAction(`${NAMESPACE} GetAllEnvironments`, props<{ coreCustomerId?: string }>());
export const getAllEnvironmentsComplete = createAction(
  `${NAMESPACE} GetAllEnvironmentsComplete`,
  props<{ environments: EnvironmentListItem[] }>(),
);
export const getAllEnvironmentsError = createAction(`${NAMESPACE} GetAllEnvironmentsError`, props<{ err: HttpErrorResponse }>());

export const goToList = createAction(`${NAMESPACE} setTableFilters`, props<{ filters: FilterToApply[] }>());

export const selectEnvironment = createAction(`${NAMESPACE} SelectEnvironment`, props<{ environmentId: string }>());
export const selectEnvironmentComplete = createAction(`${NAMESPACE} SelectEnvironmentComplete`, props<{ environment: Environment }>());
export const selectEnvironmentError = createAction(`${NAMESPACE} SelectEnvironmentError`, props<{ err: HttpErrorResponse }>());

export const setEnvironmentUpdateWindow = createAction(
  `${NAMESPACE} SetEnvironmentUpdateWindow`,
  props<{ environmentId: string; preferredStartTime: string; preferredEndTime: string }>(),
);
export const setEnvironmentUpdateWindowComplete = createAction(
  `${NAMESPACE} SetEnvironmentUpdateWindowComplete`,
  props<{ environmentId: string; res: ActionQueuedResponse }>(),
);
export const setEnvironmentUpdateWindowError = createAction(
  `${NAMESPACE} SetEnvironmentUpdateWindowError`,
  props<{ err: HttpErrorResponse }>(),
);

export const setEnvironmentUpdateDate = createAction(
  `${NAMESPACE} SetEnvironmentUpdateDate`,
  props<{ environmentId: string; runOnDate: Date; runOnTime: string; ignoreUpgradeWindow: boolean }>(),
);
export const setEnvironmentUpdateDateComplete = createAction(
  `${NAMESPACE} SetEnvironmentUpdateDateComplete`,
  props<{ environmentId: string; res: ActionQueuedResponse }>(),
);
export const setEnvironmentUpdateDateError = createAction(
  `${NAMESPACE} SetEnvironmentUpdateDateError`,
  props<{ err: HttpErrorResponse }>(),
);

export const environmentsRefresh = createAction(
  `${NAMESPACE} EnvironmentsRefresh`,
  props<{ environmentId: string; req: EnvironmentsRefreshRequest }>(),
);
export const environmentsRefreshComplete = createAction(
  `${NAMESPACE} EnvironmentsRefreshComplete`,
  props<{ environmentId: string; res: ActionQueuedResponse }>(),
);
export const environmentsRefreshError = createAction(`${NAMESPACE} EnvironmentsRefreshError`, props<{ err: HttpErrorResponse }>());

export const resetState = createAction(`${NAMESPACE} ResetState`, props<Partial<State>>());
