import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as fromActions from './dashboard.actions';
import { DashboardService } from './dashboard.service';
@Injectable()
export class DashboardEffects {
  onGetMMTileInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getMMTileInfo),
      switchMap(() =>
        this.dashboardService.getMMTileInfo().pipe(
          map(mmTileInfo => fromActions.getMMTileInfoComplete({ mmTileInfo })),
          catchError(({ error }) => of(fromActions.getMMTileInfoError({ err: error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
  ) {}
}
