import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { identitySelectors } from '@appState';
import { select, Store } from '@ngrx/store';
import { CuiDashboardModule } from 'cui-components';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app-state/app.store';
import { MMTileComponent } from './mm-tile/mm-tile.component';

@Component({
  selector: 'ocf-dashboard',
  templateUrl: './dashboard.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, CuiDashboardModule, MMTileComponent],
})
export class DashboardComponent {
  hasMMTileAccess$: Observable<boolean> = this.store.pipe(select(identitySelectors.selectHasPermission(['Portal.Business.Dashboard.MM'])));

  constructor(private readonly store: Store<AppState>) {}
}
